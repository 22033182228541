body {
    height: auto;
}

.main-about-us-page {
    margin: 0;
    padding: 0;
    background-color: #f0f4f8;
    overflow-x: hidden;
}

.main-about-us-page.dark {
    background-color: #2c2c34;
}

.main-content-about {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 0;
}

.text-container-about {
    font-size: 20px;
    width: 600px;
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    margin-top: 15px;
}

.text-container-about.dark {
    color: #ffffff;
}

.about-us-some {
    margin-top: 20px;
}

.main-photo-about {
    width: 600px;
    height: auto;
    margin-right: 100px;
 
}

.map {
    margin-right: 120px;
}

@media (max-width: 1345px) {
    .text-container-about {
        font-size: 18px;
        width: 500px;
        margin-right: 20px;
    }

    .main-photo-about {
        width: 300px;
    }
}

@media (max-width:768px) {
    .map {
        width: 100%;
    }

    .text-container-about {
        width: auto;
        margin: 0;
        font-size: 18px;
    }

    .main-content-about {
        width: auto;
        justify-content: center;
        margin-top: 20px;
    }
}