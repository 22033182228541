.message-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
}

.message-form.dark {
    background-color: #333;
}

.message-content {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #333;
    font-weight: bold;
}

.message-content.dark {
    color:#ffffff;
}

.forward-form form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}



input[type="text"], input[type="email"], textarea {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    border: 3px solid hsl(238, 74%, 61%);
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

input[type="text"]:focus, input[type="email"]:focus, textarea:focus {
    border-color: hsl(238, 74%, 61%);
    outline: none;
}

textarea {
    height: 165px;
    resize: vertical;
}

input[type="text"].dark, input[type="email"].dark, textarea.dark {
    color: #ffffff;
    background-color: #333;
}

.sent-message-text {
    color: #000000;
    text-align: center;
}

.sent-message-text.dark {
    color: #ffffff;
}


.submit-button {
    padding: 15px 20px;
    background-color: hsl(238, 74%, 61%);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    transition: background-color 0.3s ease;
    border: hsl(238, 74%, 61%) 4px solid;
}

.submit-button:hover {
    background-color: #333;
    color: #ffffff;
}

@media  (max-width: 1345px) {
    .message-form {
        max-width: 500px;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 50px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 400px;
        height: 450px;
    }

    textarea {
        height: 80px;
        resize: vertical;
    }
}


@media (max-width: 768px) {
    .message-form {
        max-width: 90%;
        padding: 15px;
        border-radius: 30px;
        width: auto;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        margin-left: 30px;
    }

    .message-content {
        font-size: 30px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    input[type="text"], input[type="email"], textarea {
        padding: 10px;
        font-size: 14px;
    }

    textarea {
        height: 120px;
    }

    .submit-button {
        padding: 10px 15px;
        font-size: 14px;
    }
}

@media (max-width: 468px) {
    .message-form {
        max-width: 90%;
        padding: 15px;
        border-radius: 30px;
        width: auto;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        margin-left: 30px;
    }

    .message-content {
        font-size: 30px;
        margin-bottom: 15px;
        margin-top: 10px;
    }

    input[type="text"], input[type="email"], textarea {
        padding: 10px;
        font-size: 14px;
    }

    textarea {
        height: 120px;
    }

    .submit-button {
        padding: 10px 15px;
        font-size: 14px;
    }
}