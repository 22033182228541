.contact-info-desc {
    background-color: #000000; 
    padding: 25px; 
    border-radius: 40px;
    text-align: left; 
    color: white; 
    width: 85%;
    margin: 0 auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s, transform 0.3s;
}

.contact-info-desc.dark {
    background-color: #333;
}

.contact-text {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px; 
    margin-bottom: 20px; 
    font-weight: bold; 
    text-align: center;
}

.contact-content ul {
    display: flex; 
    flex-direction: column;
    gap: 10px; 
    list-style-type: none;
    padding: 0;
}

.contact-content li {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    transition: background-color 0.3s;
}

.contact-content li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.contact-content img {
    width: 25px; 
    height: 25px;
    margin-right: 10px;
}

.phone_number {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px; 
    color: white;
}

.contact-link {
    color: white; 
    text-decoration: none; 
    font-family: 'Montserrat', sans-serif;
    font-size: 16px; 
    margin-left: 10px;
    transition: color 0.3s;
}

.contact-item:hover .contact-link {
    color: hsl(238, 74%, 61%);
}

.contact-icon {
    transition: filter 0.3s ease;
}

.contact-item:hover .mail-icon {
    content: url('../../shared/assets/icons/email-purple.png');
}

.contact-item:hover .phone-icon {
    content: url('../../shared/assets/icons/phone-call-purple.png');
}

.contact-item:hover .telegram-icon {
    content: url('../../shared/assets/icons/telegram-purple.png');
}

.contact-item:hover .instagram-icon {
    content: url('../../shared/assets/icons/instagram-purple.png');
}

@media (max-width: 768px) {
    .contact-info-desc {
        max-width: 90%;
        width: auto; 
        padding: 15px; 
        margin-left: 20px;
    }

    .contact-text {
        font-size: 18px; 
        margin-bottom: 15px; 
    }

    .contact-content ul {
        gap: 8px; 
    }

    .phone_number {
        font-size: 16px;
    }

    .contact-item img {
        width: 25px; 
    }
}
