.ordercall {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: hsl(238, 74%, 61%) 5px solid;
    width: 80%;
    height: 90px;
    border-radius: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    gap: 30px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.ordercall.dark {
    background-color: #1c1b24;
    padding: 0;
}

.libert-logo img {
    max-width: 70%;
    height: auto;
    margin-left: 20px;
}

.order-call-content {
    text-align: left;
    padding: 0;
    font-size: 17px;
    width: 100%;
}

.order-call-content.dark {
    color: #ffffff;
}

.order-call-button {
    background-color: #5156e5;
    color: white;
    padding: 10px 20px;
    text-transform: uppercase;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 25px; 
    width: 300px; 
    display: flex;
    margin-right: 40px;
    align-items: center;
    justify-content: center; 
    border: #5156e5 2px solid;
}

.order-call-button:hover {
    background-color: #1c1b24;
    color: #ffffff;
}

/* Modal Styles */
.modal-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    z-index: 1000;
    overflow-y: hidden;
}

.modal-content {
    background-color: #1c1b24;
    margin: 10% auto;
    padding: 30px;
    border-radius: 50px;
    width: 80%;
    height: 400px;
    max-width: 500px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    font-size: 25px;
    color: #ffffff;
    margin-top: 100px;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: #5156e5;
    border: none;
    background: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    margin-right: 10px;
}

.modal-close:hover {
    transform: scale(1.2);
}

input[type="text-order"], input[type="tel-type"] {
    width: 90%;
    padding: 15px;
    margin: 10px 0;
    margin-top: 20px;
    border: 2px solid #ccc;
    border-radius: 50px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input[type="text-type"]:focus, input[type="tel-type"]:focus {
    border-color: hsl(238, 74%, 61%);
    outline: none;
}

.order-button {
    padding: 15px 30px;
    background-color: hsl(238, 74%, 61%);
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    border: #6366f1 2px solid;
    width: 97.5%;
    margin-top: 20px;
    text-transform: uppercase;
}

.order-button:hover {
    color: #ffffff;
    background-color: #1c1b24;
}

.sent-message-text-modal {
    margin-top: 15px;
    color: #ffffff;
    font-size: 14px;
    justify-content: center;
}

.order-call-sending {
    margin-top: 30px;
    font-size: 15px;
}

@media (max-width: 1305px) {
    .order-call-content {
        font-size: 15px;
        width: 100%;
    }

    .order-call-button {
        height: 20px; 
        width: 355px; 
        margin-right: 20px;
    }
}

@media (max-width: 1250px) {
    .order-call-button {
        height: 40px; 
        width: 370px; 
        margin-right: 30px;
        font-size: 15px;
    }

    .order-call-content {
        font-size: 15px;
        width: 180%;
        margin-left: 40px;
    }

    .libert-logo img {
        max-width: 90%;
        height: auto;
        margin-left: 50px;
    }
}

@media (max-width: 1175px) {
    .order-call-button {
        height: 40px; 
        width: 300px; 
        margin-right: 30px;
        font-size: 15px;
    }

    .order-call-content {
        font-size: 15px;
        width: 300%;
    }

    .libert-logo img {
        max-width: 100%;
        height: auto;
        margin-left: 40px;
    }
}

@media (max-width: 1100px) {
    .order-call-content {
        font-size: 15px;
        width: 350%;
        margin-left: 50px;
    }

    .order-call-button {
        height: 30px; 
        width: 750px; 
        margin-right: 30px;
        font-size: 14px;
    }

    .libert-logo img {
        max-width: 150%;
        height: auto;
        margin-left: 20px;
    }
}


@media (max-width: 768px) {
    .ordercall {
        width: 87%;
        height: 55px;
        padding: 10px;
        align-items: center;
    }

    .libert-logo img {
        height: auto;
        max-width: 100%;
        margin-top: 5px;
        align-items: left;
    }

    .order-call-content {
        display: none;
    }

    .order-call-button {
        height: 20px; 
        width: 300px; 
        margin-right: 30px;
        font-size: 14px;
    }

    .modal-content {
        margin: 0;
    }
}

.message-sent {
    font-weight: bold;
    margin-top: 170px;
}