.main-contact-page {
    margin: 0;
    padding: 0;
    background-color: #f0f4f8;
    overflow-x: hidden;
}

.main-contact-page.dark {
    background-color: #2c2c34;
}

.main-contact {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: auto;
    margin-top: 30px;
    margin-bottom: 60px;
}

.contact-form {
    margin-left: 80px;
}

.contact-info {
    margin-right: 60px;
}

.map-contact {
    margin-right: 170px;
}


@media (max-width: 1305px) {
    .map-contact {
        margin-right: 80px;
        justify-content: right;
    }

    .contact-info {
        margin-right: 20px;
    }

    .contact-form {
        margin-left: 50px;
    }
}

@media (max-width: 768px) {
    .map-contact {
        display: none;
    }

    .contact-form {
        margin: 0;
        align-items: center;
    }

    .main-contact {
        margin-bottom: 40px;
    }

    .contact-info {
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 0;
        margin-left: 5px;

    }
}
