h3 {
    margin: 0;
    padding: 0;
}

.header {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    height: 80px;
    width: 100%;
    background-color: #dbdde0;
    margin-bottom: 50px;
}

.active {
    color: #5156e5;
    font-weight: bold;
}

.header.dark {
    background-color: #1c1b24;
    color: #dbdde0;
}

.header-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Logo Section */
.logo-section {
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
}

.logo {
    height: 200px;
    width: auto;
    margin: 0;
    transition: opacity 0.3s ease;
}

.logo.dark {
    background: url("../../assets/images/libert_logo_white.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* Language Switcher */
.language-switcher {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.flag-icon {
    width: 30px;
    height: auto;
}

.arrow-icon {
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

.arrow-icon.dark {
    background-image: url('../../assets/icons/arrow-down-white.png');
    background-size: cover; 
}

/* Navigation */
.navigation {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    margin-left: 120px;
}

.nav-link {
    position: relative;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    transition: color 0.2s ease;
    color: #000;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -2px;
    left: 50%;
    background-color: #6366f1;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.nav-link:hover::after {
    width: 100%;
}

.nav-link.dark {
    color: #fff;
}

.nav-link.active {
    color: #5156e5;
    font-weight: bold;
    position: relative;
}

.nav-link.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background: linear-gradient(90deg, #5156e5, #6366f1);
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.3s ease;
}

.nav-link:hover {
    color: #6366f1;
}

/* Dropdown Menu */
.dropdown-menu {
    position: absolute;
    top: 100%; /* Position below the parent element */
    left: 0;
    width: 180px;
    color: #ffffff;
    background-color: #000000;
    border: 3px solid #5156e5;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin-top: 5px; /* Small margin to create space between the parent and dropdown */
}

/* Dropdown Item Layout */
.dropdown-item {
    display: flex;   
    margin-top: 5px;           /* Use flexbox to align items */
    align-items: center;         /* Center items vertically */
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 30px;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.dropdown-language {       
    text-decoration: none;
    color: #ffffff;
    transition: background-color 0.2 ease, color 0.2 ease;
}

.dropdown-item img {
    width: 20px;
    height: 20px;
    margin-right: 10px;          
}

.dropdown-language:hover {
    color: #5156e5;
    border-radius: 30px;
}

.dropdown-item:hover {
    color: #5156e5;
    background-color: #333; 
}

.service-dropdown {
    padding: 10px 20px;
    border-radius: 30px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.service-dropdown-link {
    text-decoration: none;
    color: #ffffff;
    display: block; 
    padding: 10px;
    border-radius: 30px;
    transition: color 0.2s ease;
    position: relative;
}

.service-dropdown-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.service-dropdown-link:hover::after {
    width: 80%;
}

.service-dropdown-link:hover {
    color: #5156e5;
}

.dropdown-item-services {
    padding: 5px;
    color: #000000;
    border-radius: 30px;
    transition: background-color 0.2s ease, color 0.2s ease;
    position: relative;
}

.dropdown-item-services::after {
    content: '';
    position: absolute;
    width: 0;
    bottom: 0;
    left: 50%;
    background-color: #5156e5;
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.dropdown-item-services:hover::after {
    width: 80%;
}

.dropdown-item-services:hover {
    .dropdown-item {
        display: flex;   
        margin-top: 5px;           /* Use flexbox to align items */
        align-items: center;         /* Center items vertically */
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 30px;
        color: #ffffff;
        text-decoration: none;
        transition: background-color 0.2s ease;
    }
    
    .dropdown-language {       
        text-decoration: none;
        color: #ffffff;
        transition: background-color 0.2 ease, color 0.2 ease;
    }
    
    .dropdown-item img {
        width: 20px;
        height: 20px;
        margin-right: 10px;          
    }
    
    .dropdown-language:hover {
        color: #5156e5;
        border-radius: 30px;
    }
    
    .dropdown-item:hover {
        color: #5156e5;
        background-color: #333; 
    }
    
    .service-dropdown {
        padding: 10px 20px;
        border-radius: 30px;
        color: #ffffff;
        cursor: pointer;
        transition: background-color 0.2s ease;
    }
    
    .service-dropdown-link {
        text-decoration: none;
        color: #ffffff;
        display: block; 
        padding: 10px;
        border-radius: 30px;
        transition: color 0.2s ease;
        position: relative;
    }
    
    .service-dropdown-link::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 50%;
        transition: all 0.3s ease;
        transform: translateX(-50%);
    }
    
    .service-dropdown-link:hover::after {
        width: 80%;
    }
    
    .service-dropdown-link:hover {
        color: #5156e5;
    }
    
    .dropdown-item-services {
        padding: 5px;
        color: #000000;
        border-radius: 30px;
        transition: background-color 0.2s ease, color 0.2s ease;
        position: relative;
    }
    
    .dropdown-item-services::after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 50%;
        background-color: #5156e5;
        transition: all 0.3s ease;
        transform: translateX(-50%);
    }
    
    .dropdown-item-services:hover::after {
        width: 80%;
    }
    
    .dropdown-item-services:hover {
        background-color: rgba(200, 200, 202, 0.1);
        color: #5156e5;
    }
    
    
    background-color: rgba(200, 200, 202, 0.1);
    color: #5156e5;
}




/* Call Order Button */
.call-order-btn {
    background-color: #5156e5;
    color: #fff;
    border: none;
    width: 220px;
    height: auto;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    border: #5156e5 2px solid;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.call-order-btn:hover {
    color: #ffffff;
    background-color: #1c1b24;
}

/* Theme Switcher */
.theme-switcher {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.theme-icon {
    width: 35px;
    height: auto;
}

.theme-icon.dark {
    background-image: url('../../assets/icons/moon.png');
    background-size: cover; /* Ensure the background image covers the element */
}

.toggle-switch {
    width: 50px; 
    height: 25px; 
    border-radius: 12.5px; 
    background-color: #c4c4c4;
    position: relative;
    transition: background-color 0.3s ease;
    margin-left: 20px;
    margin-right: 10px;
}

.toggle-switch::after {
    content: '';
    position: absolute;
    top: 1px; /* Adjusted top position */
    left: 1px; /* Adjusted left position */
    width: 23px; /* Adjusted width */
    height: 23px; /* Adjusted height */
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch.active {
    background-color: #6366f1; /* Background when active */
}

.toggle-switch.active::after {
    transform: translateX(25px); /* Move to the right when active */
}

/* Header Actions */
.header-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-right: 5%;
}

/* Mobile Menu Button */
.mobile-menu-button {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 50px;
}

.mobile-menu-button .bar {
    width: 30px;
    height: 3px;
    margin: 4px 0;
    transition: 0.4s;
    background-color: #333;
}

.mobile-menu-button.dark .bar {
    background-color: #ffffff;
}

.mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease;
    opacity: 1;
}

.mobile-menu {
    background: #1c1b24;
    padding: 30px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
    position: relative;
    width: 90%;
    height: 100%;
    max-width: 400px;
    color: #fff;
    overflow-y: auto; 
    max-height: 100vh; 
}

/* Navigation Links */
.mobile-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}

/* Burger Menu */
.burger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-align: right;
    justify-content: right;
    background: none;
    border: none;
    z-index: 1000;
    padding: 10px;
    margin-bottom: 50px;
}

.burger-line {
    width: 25px;
    height: 3px;
    margin: 3px 0;
    background-color: #fff;
    transition: 0.4s;
}

/* Mobile Menu Button */
.mobile-menu-button {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.mobile-menu-button .bar {
    width: 30px;
    height: 3px;
    margin: 4px 0;
    transition: 0.4s;
    background-color: #333;
}

.mobile-menu-button.dark .bar {
    background-color: #ffffff;
}


.mobile-nav-link {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    padding: 15px 25px;
    max-width: 70%;
    width: auto;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.mobile-nav-link:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
}

/* Dropdown Menu */
.dropdown-menu-mobile {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    margin-top: 10px;
    padding: 10px 15px;
    transition: background 0.3s ease, padding 0.3s ease;
}

.dropdown-item-services-mobile {
    font-size: 16px;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    transition: color 0.3s ease, padding 0.3s ease;
}

.dropdown-item-services-mobile:hover {
    color: #333;
    background: rgba(255, 255, 255, 0.1);
}

/* Social Media Icons */
.social-media-mobile {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
}

.social-media-mobile img {
    width: 40px;
    filter: grayscale(100%);
    transition: transform 0.3s ease, filter 0.3s ease;
}

.social-media-mobile img:hover {
    transform: scale(1.2);
    filter: grayscale(0%);
}

/* Footer */
.mobile-modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

.mobile-modal-footer-description {
    color: #fff;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 18px;
}

.mobile-modal-footer-bottom {
    font-family: 'Oswald', sans-serif;
    font-size: 14px;
    color: #fff;
    opacity: 0.8;
}

.navigation {
    font-size: 16px;;
    margin-left: 60px;
}


@media (max-width:1305px) {
    .theme-icon {
        width: 25px;
        height: auto;
    }

    .navigation {
        margin-left: 5px
    }
    
    .theme-icon.dark {
        background-image: url('../../assets/icons/moon.png');
        background-size: cover;
    }
    
    .toggle-switch {
        width: 50px; 
        height: 25px; 
        border-radius: 12.5px; 
        background-color: #c4c4c4;
        position: relative;
        transition: background-color 0.3s ease;
        margin-left: 20px;
        margin-right: 10px;
    }
    
    .toggle-switch::after {
        content: '';
        position: absolute;
        top: 1px; 
        left: 1px;
        width: 23px; 
        height: 23px; 
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    .nav-link {
        font-size: 15px;
        margin: 0;
    }

    .call-order-btn {
        background-color: #5156e5;
        color: #fff;
        border: none;
        width: 200px;
        height: auto;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        border: #5156e5 2px solid;
        text-transform: uppercase;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
}


@media (max-width: 1125px) {
    .theme-icon {
        width: 20px;
        height: auto;
    }

    .navigation {
        margin-left: 2px;
        gap: 30px;
    }
    
    .theme-icon.dark {
        background-image: url('../../assets/icons/moon.png');
        background-size: cover; 
    }
    
    .toggle-switch {
        width: 50px; 
        height: 25px; 
        border-radius: 12.5px; 
        background-color: #c4c4c4;
        position: relative;
        transition: background-color 0.3s ease;
        margin-left: 20px;
        margin-right: 10px;
    }
    
    .toggle-switch::after {
        content: '';
        position: absolute;
        top: 1px; 
        left: 1px; 
        width: 23px; 
        height: 23px; 
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    .nav-link {
        font-size: 12px;
        margin: 0;
    }

    .call-order-btn {
        background-color: #5156e5;
        color: #fff;
        border: none;
        width: 200px;
        height: auto;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        border: #5156e5 2px solid;
        text-transform: uppercase;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
}

@media (max-width: 1050px) {
    .theme-icon {
        width: 20px;
        height: auto;
    }

    .navigation {
        margin-left: 2px;
        gap: 10px;
    }
    
    .theme-icon.dark {
        background-image: url('../../assets/icons/moon.png');
        background-size: cover;
    }
    
    .toggle-switch {
        width: 50px; 
        height: 25px; 
        border-radius: 12.5px; 
        background-color: #c4c4c4;
        position: relative;
        transition: background-color 0.3s ease;
        margin-left: 20px;
        margin-right: 10px;
    }
    
    .toggle-switch::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px; 
        width: 23px; 
        height: 23px; 
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }

    .nav-link {
        font-size: 12px;
        margin: 0;
    }

    .call-order-btn {
        background-color: #5156e5;
        color: #fff;
        border: none;
        width: 185px;
        height: auto;
        padding: 10px 20px;
        font-size: 13px;
        font-weight: 600;
        border-radius: 10px;
        border: #5156e5 2px solid;
        text-transform: uppercase;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }
}


@media (max-width: 768px) {
    .navigation, .call-order-btn {
        display: none;
    }

    .mobile-menu-button {
        display: flex;
    }

    .mobile-menu-overlay {
        display: block;
    }

    .flag-icon {
        width: 20px;
        height: auto;
    }


    .logo {
        width: 140px;
        height: auto;
        margin: 0;
    }

    .logo-section {
        margin: 0;
    }


    .theme-icon {
        width: 25px;
        height: auto;
    }

    .toggle-switch {
        width: 30px; /* Adjusted width */
        height: 15px; /* Adjusted height */
        border-radius: 7.5px; /* Adjusted border-radius */
        background-color: #c4c4c4;
        position: relative;
        transition: background-color 0.3s ease;
    }
    
    .toggle-switch::after {
        content: '';
        position: absolute;
        top: 1px; /* Adjusted top position */
        left: 1px; /* Adjusted left position */
        width: 13px; /* Adjusted width */
        height: 13px; /* Adjusted height */
        background-color: #fff;
        border-radius: 50%;
        transition: transform 0.3s ease;
    }
    
    .toggle-switch.active {
        background-color: #6366f1;
    }
    
    .toggle-switch.active::after {
        transform: translateX(15px); 
    }
    .mobile-nav-link {
        font-size: 16px;
        padding: 12px 20px;
        width: 90%;
    }

    .dropdown-item-services-mobile {
        font-size: 14px;
        padding: 8px;
    }
    
}

@media (max-width: 480px) {
    .mobile-nav-link {
        font-size: 14px;
        padding: 10px 15px;
        width: 95%;
    }

    .dropdown-item-services-mobile {
        font-size: 12px;
        padding: 6px;
    }

    .header-actions {
        margin: 0;
    }

    .theme-icon {
        width: 20px;
    }

    .header {
        margin-left: 0;
        padding-left: 0;
    }

    .logo {
        max-width: 90%;
        height: auto;
    }

    .logo-section {
        max-width: 90%;
    }
}

/* Modal Styles */
.modal-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(5px);
    z-index: 1000;
}

.modal-content {
    background-color: #1c1b24;
    padding: 30px;
    border-radius: 50px;
    width: 95%;
    max-width: 400px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    font-size: 25px;
    color: #ffffff;
}

.modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: #5156e5;
    border: none;
    background: none;
    cursor: pointer;
    transition: transform 0.2s ease;
    margin-right: 10px;
}

.message-order-sent-form {
    margin-top: 12%;
}

.modal-close:hover {
    transform: scale(1.2);
}

input[type="text-name"], input[type="tel"] {
    width: 90%;
    padding: 15px;
    margin: 10px 0;
    margin-top: 20px;
    border: 2px solid #ccc;
    border-radius: 50px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

input[type="text-name"]:focus, input[type="tel"]:focus {
    border-color: hsl(238, 74%, 61%);
    outline: none;
}

.order-button {
    padding: 15px 30px;
    background-color: hsl(238, 74%, 61%);
    color: white;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    border: #5156e5 2px solid;
    width: 97.5%;
    margin-top: 20px;
    text-transform: uppercase;
}

.order-button:hover {
    color: #ffffff;
    background-color: #1c1b24;
}