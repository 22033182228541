.services-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 100px;
}

.service-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 5px;
    width: calc(45% - 20px);
    box-sizing: border-box;
}

.service-content .service-text-link {
    text-decoration: none;
    color: #5156e5;
}

.logo-background {
    width: 300px;
    height: auto;
    padding: 10px;
    border-radius: 40px;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease, border 0.5s ease;
    border: #5156e5 1px solid;
}

.logo-background:hover {
    background-color: #1c1b24;
}

.service-image {
    width: 300px;
    height: auto;
    object-fit: cover;
    border-radius: 40px;
    transition: opacity 0.5s ease;
}

.service-text {
    cursor: pointer;
    color: #5156e5;
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    font-weight: bold; 
    font-size: 30px; 
    opacity: 0; 
    text-decoration: none; 
    transition: opacity 0.3s ease, transform 0.3s ease; 
}

.logo-background:hover .service-text {
    opacity: 1;
}

.service-text:hover {
    color: #3d43d0; 
}

.logo-background:hover .service-image {
    opacity: 0;
}

@media (max-width: 1200px) {
    .service-image {
        width: 250px;
    }

    .logo-background {
        width: 250px;
    }
}

@media (max-width: 768px) {
    .service-content {
        display: flex;
    }

    .logo-background {
        width: 170px; 
        height: 150px; 
        border-radius: 30px;
        padding-right: 25px; 
    }

    .service-image {
        width: auto;
        height: 100%;
        object-fit: cover;
        border-radius: 30px; 
    }

    .service-text {
        font-size: 16px; 
    }
}

@media (max-width: 450px) {
    .logo-background {
        width: 120px; 
        height: 120px;
        border-radius: 20px;
        margin: 0;
        gap: 0;
        display: flex;
    }

    .service-text {
        font-size: 16px; 
    }

    .service-image {
        border-radius: 20px;
    }
}

@media (max-width: 350px) {
    .logo-background {
        width: 150px; 
        height: 170px;
        border-radius: 15px;
        display: flex;
    }

    .service-text {
        font-size: 12px; 
    }

    .service-image {
        border-radius: 15px;
    }
}
