.main-map-page {
    background-color: white;
    border-radius: 30px;
}

.map-section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gmap-frame {
    height: 550px;
    width: 550px;
    overflow: hidden; /* To prevent the iframe from exceeding the rounded corners */
    background-color: white;
    border-radius: 30px;
}

#gmap-canvas {
    border-radius: 30px;
    width: 650px;
    height: 650px;
    border: none; /* Removes default iframe border */
}

@media (max-width:1345px) {
    .gmap-frame {
        height: 490px;
        width: 490px;
        overflow: hidden; /* To prevent the iframe from exceeding the rounded corners */
        background-color: white;
        border-radius: 30px;
    }
    
    #gmap-canvas {
        border-radius: 30px;
        width: 550px;
        height: 490px;
        border: none; /* Removes default iframe border */
    }
}