.footer {
    background-color: #5156e5;
    color: white;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    border-radius: 90px;
    max-width: 100%;
    width: 92%;
    height: auto; 
    transform: translateY(100%); 
    animation: slideIn 0.5s forwards; 
    margin-top: 60px;
}

@keyframes slideIn {
    0% {
        transform: translateY(100%); 
        opacity: 0; 
    }
    100% {
        transform: translateY(0); 
        opacity: 1; 
    }
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap; 
    width: 100%; 
    margin-left: 80px;
}

.footer-column {
    flex: 1; 
    margin-top: 3%;
}

.footer-column ul {
    padding: 0; 
    list-style: decimal; 
}

.footer-column-name img h3 {
    font-weight: 700;
    margin-bottom: 0;
}

.footer-column ul li {
    margin-top: 10px; 
    display: flex;
    align-items: center;
    font-size: 16px; 
    position: relative; 
    padding-left: 20px; 
}

.footer-column li:hover {
    font-weight: bold;
    cursor: pointer;
}


.footer-column ul li::before {
    content: '•';
    position: absolute; 
    left: 0; 
    color: white; 
    font-size: 16px; 
}

.footer-column-name {
    display: flex;
    align-items: center; 
}

.footer-column-name h3 {
    margin-right: 10px; 
    transition: margin-right 0.3s ease; 
}

.footer-column-name h3:hover {
    margin-right: 15px; 
    cursor: pointer;
}

.footer-column-name img {
    display: block; 
    margin-top: 5px;;
}

.footer-column ul li img {
    margin-right: 8px;
    width: 25px;
    height: auto;
}

.social-media {
    margin-top: 20px;
    justify-content: left;
    display: flex;
    margin-left: 15px;
}

.social-media img {
    height: auto;
    width: 40px; 
    margin-right: 15px;
    cursor: pointer;
    transition: transform 0.3s ease; 
}

.social-media img:hover {
    transform: scale(1.2); 
}

.footer-bottom {
    text-align: center; 
    margin-top: 30px;
    font-weight: 400;
}

.footer-bottom p {
    margin: 0;
    font-size: 14px;
}

.li-link, .footer-link {
    text-decoration: none;
    color: #ffffff;
}


@media (max-width: 1420px) {
    .footer-container {
        margin-left: 30px;
    }

    .footer-column ul li {
        font-size: 15px;
    }

    .social-media {
        justify-content: left;
        display: flex;
        margin-left: 15px;
    }
}

@media (max-width: 1150px) {
    .footer-container {
        margin-left: 20px;
    }

    .footer-column ul li {
        font-size: 13px;
    }

    .social-media {
        justify-content: left;
        display: flex;
        margin-left: 15px;
    }
}

@media screen and (max-width: 768px) {
    .footer {
        max-width: 85%;
        width: auto;
        padding: 30px 10px;
        border-radius: 40px;
    }

    .footer-container {
        flex-direction: column;
        align-items: center; 
        margin: 0;
    }

    .footer-column {
        flex: none;
        margin: 10px 0;
        width: 100%;
        text-align: center; 
    }

    .footer-column h3 {
        font-size: 16px;
    }

    .footer-column ul li {
        font-size: 14px;
    }

    .social-media {
        justify-content: center;
        width: auto;
        margin: 0;
    }

    .social-media img {
        width: 40px;
    }

    .footer-bottom p {
        font-size: 13px;
    }
}

@media (max-width: 385px) {
    .footer-bottom p {
        font-size: 12px;
    }

    .footer-column ul li {
        font-size: 12px;
    }
}

