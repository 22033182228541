.service-page {
    margin: 0;
    padding: 0;
    background-color: #f0f4f8;
    overflow-x: hidden;
}

.service-page.dark {
    background-color: #2c2c34;
}

.service-content-about {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.service-container-about {
    font-size: 23px;
    max-width: 950px;
    width: auto;
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    margin-top: 15px;
}

.fullfilment-list-style {
    list-style: none;
}

.service-container-about.dark {
    color: #ffffff;
}

.service-image-main-container {
    justify-content: center;
    margin-right: 220px;
    margin-top: 30px;
    max-width: 70%;
}

.service-image-desc {
    width: 400px;
    height: auto;
}

.text-container-about li {
    margin-top: 20px;
}

.logo-service-background-desc {
    margin-right: 160px;
    margin-top: 40px;
}

@media (max-width: 1305px) {
    .service-image-main-container {
        margin-right: 100px;
    }

    .service-image-desc {
        width: 350px;
        height: auto;
    }
}

@media (max-width: 1100px) {
    .service-image-main-container {
        margin-right: 100px;
    }

    .service-image-desc {
        width: 300px;
        height: auto;
    }
}


@media (max-width:768px) {
    .map {
        display: none;
    }

    .text-container-about {
        max-width: 90%;
        width: auto;
        justify-content: center;
        font-size: 15px;
        margin-left: 20px;
        margin-top: 20px;
    }

    .service-container-about {
        width: 1000px;
    }

    .service-image-desc {
        display: none;
        width: 0;
        background-color: none;
        justify-content: center;
        margin-right: 30px;
    }

    .service-image-main-container {
        display: none;
    }
}

