.main-page-desc {
    background-color: #f0f4f8;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.some-cont {
    font-weight: bold;
    color: #5156e5;
}

.main-page-desc.dark {
    background-color: #2c2c34;
}

.main-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.text-content-main {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.company-main-name-desc,
.highlites {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 60px;
    text-transform: uppercase;
    line-height: 1.2;
}

.company-main-name-desc.dark {
    color: #ffffff;
}

.main-photo-desc {
    width: 40%;
    max-height: auto;
    margin-right: 80px;
}

.highlites {
    display: inline;
    color: #5156e5;
}

.text-container-main {
    font-size: 17px;
    max-width: 600px;
    line-height: 1.6;
    margin-left: 5px;
}

.text-container-main a {
    color: #5156e5;
}

.text-container-main.dark {
    color: #ffffff;
}

.btn-main-desc {
    background-color: #5156e5;
    width: 420px;
    height: 55px;
    margin-left: 3px;
    font-weight: bold;
    font-size: 18px;
    padding: 12px;
    text-transform: uppercase;
    color: #ffffff;
    border: 2px solid #5156e5;
    border-radius: 50px;
    transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
    display: flex; 
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.btn-main-desc:hover {
    background-color: #2c2c34;
    color: #5156e5;
    transform: scale(1.05);
}

.btn-link-desc {
    text-decoration: none; 
    text-transform: uppercase;
    color: inherit; 
}

.btn-main-desc:hover .btn-link-desc {
    color: inherit; 
}

@media (max-width: 1250px) {
    .company-main-name-desc,
    .highlites {
        font-size: 40px;
    }

    .text-container-main {
        font-size: 13px;
        max-width: 539px;
    }

    .main-photo-desc {
        height: 400px;
    }
}

@media (max-width: 1200px) {
    .text-container-main {
        font-size: 12px;
        max-width: 498px;
    }
}

@media (max-width: 1100px) {
    .text-container-main {
        font-size: 12px;
        max-width: 470px;
    }

    .main-photo-desc {
        max-width: 40%;
        height: auto;
    }

    .btn-link-desc {
        max-width: 200px;
        font-size: 12px;
    }
}

@media (max-width: 769px) {
    .main-photo-desc {
        display: none;
    }

    .text-content-main {
        margin-left: 25px;
    }

    .company-main-name-desc,
    .highlites {
        font-size: 35px;
        line-height: 1.1;
    }

    .text-container-main {
        font-size: 16px; 
        max-width: 300px; 
        margin-left: 0; 
        margin-right: 10px; 
    }
    
    .btn-main-desc {
        max-width: 95%;
        width: auto;
        margin-left: 0;
        height: auto; 
        font-size: 18px;
        padding: 10px;
        border: 4px solid #5156e5; 
    }

    @media (max-width: 480px) {
        .text-container-main {
            font-size: 14px; 
            max-width: 300px; 
            margin-left: 0; 
            margin-right: 10px; 
        }
    }
}